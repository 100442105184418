import logo from './logo.svg';
import './App.css';
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from "@livekit/components-react";
import "@livekit/components-styles";
import { Track } from "livekit-client";
import axios from "axios";
import {useEffect, useState} from "react";

const tokenAPIUrl = "https://autogence.xyz/getToken"
const livekitURL = "wss://vastream.autogence.xyz"
console.log(tokenAPIUrl)
console.log("what??")

function App() {
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Define the async function to fetch data
        const getToken = async () => {
            try {
                console.log("getting token from: " + tokenAPIUrl)
                const response = await axios.get(tokenAPIUrl);
                setToken(response.data); // Update state with the fetched data
                console.log(response.data);
            } catch (error) {
                setError(error.message); // Update state with the error message
            } finally {
                setLoading(false); // Update state to indicate loading is complete
            }
        };

        // Call the function
        getToken();
    }, []);

  return (
      <LiveKitRoom
          video={true}
          audio={true}
          token={token}
          serverUrl={livekitURL}
          // Use the default LiveKit theme for nice styles.
          data-lk-theme="default"
          style={{ height: '100vh' }}
      >
        {/* Your custom component with basic video conferencing functionality. */}
        <MyVideoConference />
        {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
        <RoomAudioRenderer />
        {/* Controls for the user to start/stop audio, video, and screen
      share tracks and to leave the room. */}
        <ControlBar />
      </LiveKitRoom>
  );
}

function MyVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
      [
        { source: Track.Source.Camera, withPlaceholder: true },
        { source: Track.Source.ScreenShare, withPlaceholder: false },
      ],
      { onlySubscribed: false },
  );
  return (
      <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
        {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
        <ParticipantTile />
      </GridLayout>
  );
}

export default App;
